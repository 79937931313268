.navigation {
  .level0 {
    &.active, &.has-active {
      > .level-top {
        color: $color-tertiary;
      }
    }
    .submenu {
      li {
        &.parent-category{
          color: $color-tertiary;
        }
      }
      a {
        &:hover, &.ui-state-focus {
          color: $color-tertiary;
        }
      }
      .active {
        > a {
          color: $color-tertiary;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .nav-toggle {
    display: block;
  }
  .nav-sections {
    &-item {
      &-title {
        &.active a {
          color: $color-tertiary;
        }
      }
    }
    .header__settings {
      .settings__content {
        .module {
          &__links {
            li {
              &:first-child:before {
                color: $color-tertiary;
              }
            }
          }
        }
        .account-buttons {
          .btn--primary {
            border: 1px solid $color-tertiary;
            background-color: $color-tertiary;
            &:hover {
              color: $color-white;
              border-color: $color-white;
              background-color: $color-dark;
            }
          }
        }
      }
    }
  }

  .navigation {
    li {
      &.level0 {
        > .level-top {
          &:hover {
            color: $color-tertiary;
          }
        }
        .submenu {
          li {
            a {
              &:hover {
                color: $color-tertiary;
              }
            }
          }
        }
      }
    }
  }

  .navigation-secondary {
    li {
      a {
        &:hover {
          color: $color-tertiary;
        }
      }
    }
  }
}