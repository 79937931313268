body {
    p{
        font-family: "TT Hoves Regular";
        span{
            font-family: "TT Hoves Regular";
        }
    }
}

a {
  &:hover {
    color: $color-tertiary;
  }
}
