.module-minicart {
  .action {
    .counter {
      background: $color-white;
      &:before {
        border-right: 3px solid $color-white;
      }
    }
  }
}
