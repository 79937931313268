.block-search {
  .block-content {
    .minisearch {
      &__content {
        .minisearch__logo {
          width: 100%;
          max-width:200px;
        }
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .block-search  {
    .block-content {
      .minisearch__content {
        .minisearch__logo {
          left:20px;
          top:20px;
        }
      }
    }
  }
}
