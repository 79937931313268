.page-header {
    .header {
        &__top {
            border-bottom: 1px solid $color-secondary;
            color: $color-white;
            font-family: $font-primary;
            background: $color-secondary;

            &--right {
                .btn--black {
                    background-color: $color-primary;
                    border: 1px solid $color-primary;

                    &:hover {
                        background-color: $color-quaternary;
                        border: 1px solid $color-secondary;
                    }
                }

                .btn--primary {
                    &:hover {
                        background-color: $color-quaternary;
                    }
                }
            }
        }

        //&__logo {
        //    top: 40px;
        //
        //    img {
        //        max-width: 70%;
        //        max-height: 140px;
        //    }
        //}

        &__secondary {
            .list-inline {
                &-item {
                    &.navigation--toggle {
                        color: $color-secondary;

                        &:before {
                            color: $color-white !important;
                        }
                    }
                }
            }
        }

        &__content {
            border-bottom: 0;
        }

        &__heading {
            background: $color-secondary;

            .page-title-wrapper {
                .page-title {
                    color: $color-primary;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .page-header {
        .header {
            &__logo {
                .nav-toggle {
                    color: $color-primary;
                }
            }

            &__right {
                .header__settings .settings__toggle {
                    color: $color-primary;
                }

                .ayudacontacto .flex-content .icono::after {
                    color: $color-primary;
                }

                .module-minicart {
                    .action.showcart:before {
                        color: $color-primary;
                    }
                }
            }

            &__content {
                background-color: transparent;
            }

            //&__top {
            //    padding-top: 0;
            //}
        }
    }
    .page-header.page-header .header__right .block-search .block-content .minisearch__trigger {
        background-color: transparent;
    }
}

//@include media-breakpoint-down(xs) {
//    .page-header {
//        margin-bottom: 15px;
//
//        .header {
//            &__logo {
//                @include make-col(9);
//
//                .logo {
//                    padding: 0;
//                    line-height: 30px;
//                }
//            }
//
//            &__right {
//                @include make-col(3);
//            }
//        }
//    }
//}

.header.header .header__right .block-search .block-content .minisearch__trigger::before {
    color: $color-primary;
}

