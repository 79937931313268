.cms-index-index {
  .page-header {
    .header {
      //&__top {
      //    //background-color: transparent;
      //    color: $color-primary;
      //}
      //&__content {
      //  //background-color: $color-quaternary;
      //}
      &__secondary {
        .list-inline {
          &-item {
            a {
              color: $color-primary;
            }
            &.navigation--toggle {
              color:$color-primary ;
                &:before {
                  color:$color-primary !important;
              }
            }
          }
        }
      }
        &__right{
            .block-search .block-content .minisearch__trigger {
                background-color: transparent;
            }
        }
    }
    &.fixed {
      .header {
        &__content {
          background-color: $color-quaternary;
        }
        &__logo{
            .logo{
                img{
                    max-height: 40px;
                }
            }
        }
      }
        .header__right{
            .block-search .block-content .minisearch__trigger {
                background-color: $color-quaternary;
            }
            .header__settings .settings__toggle{
                color: $color-primary;
            }
            .ayudacontacto .flex-content .icono::after{
                color: $color-primary;
            }
        }
    }
  }
  .page-main{
      .columns .column.main{
          background-color: transparent;
      }
  }
  .block-search {
    .block-content {
      .minisearch__trigger {
        //background-position: left center;
        color: $color-primary;
      }
    }
  }
  .module-minicart {
    .action.showcart:before {
      //background-position: left center;
      color: $color-primary;
    }
  }
  .header__settings {
    .settings__toggle {
      color: $color-white;
    }
  }
}
@include media-breakpoint-down(xs) {
    .cms-index-index {
        .page-header {
            .page-header.fixed .header__logo .logo img {
                max-height: 40px;
            }
        }
    }
}
