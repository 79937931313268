.footer .module-footer {
    background-color: $color-black;

    .custom-checkbox .custom-control-description a {
        color: $color-white;
    }

    .module-inner {
        .footer__logo {
            height: 50px;
        }
        .footer-container .footer-content .footer__content{
            .social-networks__item {
                margin: 0;

                .fa {
                    color: $color-white;
                    padding-right: 10px;
                }
            }
            .footer__links{
                .content-title h2{
                    color: $color-white;
                }
            }

        }
        .module-showroon .layer-transparent {
            .module__title a{
                color: $color-white;
            }
        }
    }
}
