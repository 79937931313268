.logoContainer .header__logo {
    top: 10px;

    .logo img {
        max-width: 175px;
        max-height: 100px;
    }
}

@include media-breakpoint-down(lg) {
    .page-header .header__logo {
        top: 40px;
    }
}

@include media-breakpoint-down(sm) {
    .page-header .header__logo {
        .logo img{
            max-height: 40px;
        }
    }
}
