.module-featuredimages {
    background-color: $color-background;

    .module-inner{
        .button-image:hover .btn-img {
            color: $color-tertiary;
            border-color: $color-tertiary;
        }
    }
}
