.cms-page-view{
    .columns .main{
        h1,h2,h3{
            border: 0;
        }

        a{
            text-decoration: none;
        }
    }
}
