@font-face {
    font-family: 'Roca Bold';
    src: url('../fonts/Roca_Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roca Regular';
    src: url('../fonts/Roca_Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roca Thin';
    src: url('../fonts/Roca_Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Hoves DemiBold';
    src: url('../fonts/TT_Hoves_DemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Hoves Regular';
    src: url('../fonts/TT_Hoves_Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Hoves Thin';
    src: url('../fonts/TT_Hoves_Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Blank-Theme-Icons";
    src: url("../fonts/Blank-Theme-Icons.eot");
    src: url("../fonts/Blank-Theme-Icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Blank-Theme-Icons.woff") format("woff"),
    url("../fonts/Blank-Theme-Icons.ttf") format("truetype"),
    url("../fonts/Blank-Theme-Icons.svg#Blank-Theme-Icons") format("svg");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'juinsatech-icons';
    src: url('../fonts/icomoon.eot?u7mbk9');
    src: url('../fonts/icomoon.eot?u7mbk9#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?u7mbk9') format('truetype'),
    url('../fonts/icomoon.woff?u7mbk9') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'juinsatech-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
