.module-footer {
  background-color: $color-primary;
  > .module {
    &-inner {
      .footer {
        &__content {
          .social-networks {
            span {
              color: $color-white;
            }
          }
        }
        &__links {
          .list-inline {
            &-item {
                font-family: "TT Hoves Regular";
              a {
                color:$color-white;
                font-size: 1rem;
              }
            }
          }
        }
      }
      .copyright {
        &__content {
          .list-inline {
            &-item {
              font-family: "TT Hoves Regular";
              color: $color-white;
              a{
                color: $color-white;
              }
            }
          }
        }
      }
      .social-networks {
        color: $color-white;
        .fa {
          color: $color-white;
        }
      }
    }
  }
}
